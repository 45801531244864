<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-orcamento
      v-model="orcamentos"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.orcamentos')"
      obrigatorio
      multiplos
      :disabled="form.status == 'Concluido'"
    />
    <input-select
      v-model="form.subCategoriaOrcamento"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaOrcamento"
      :loading="loading.subCategoriaOrcamento"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputOrcamento } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
components: {
    InputOrcamento,
  },
  props: {
    form: {}
  },
  data() {
    return {
      orcamentos: null,
      loading: {
        subCategoriaOrcamento: true,
      },
      opcoes: {
        subCategoriaOrcamento: [],
      },
    };
  },
  watch: {
    orcamentos(value) {
      if(!this.form.id) {
        this.form.orcamentos = value
      } else {
        value.forEach((orcamento) => {
          let orcamentoInserido = this.form.orcamentos.find((el) => el.orcamentoId == orcamento.value);
          if (!orcamentoInserido) {
              this.form.orcamentos.push({
                id: null,
                orcamentoId: orcamento.value,
              });
          }
        });
      }
    }
  },
  mounted() {
    this.buscarSubCategoria();
    if(this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function() {
      this.form.orcamentos = []
      this.orcamentos = new DropdownModel(this.form.orcamentosEditar.map(item => item.orcamento), 'codigo')

      this.form.orcamentosEditar.forEach((orcamento) => {
            this.form.orcamentos.push({
              id: orcamento.id,
              orcamentoId: orcamento.orcamento.id,
            });
      });
    },
    buscarSubCategoria: function(){
      this.loading.subCategoriaOrcamento = true;
      EnumeradorService.buscar('EnumSubCategoriaDuvidaSolicitacaoParticipanteOrcamento')
      .then((res) => {
        this.opcoes.subCategoriaOrcamento = res
      })
      .finally(() => {
        this.loading.subCategoriaOrcamento = false;
      });
    },
  }
};
</script>
