export class DropdownCertificado {
  constructor(lista, texto = 'codigo') {
    if (Array.isArray(lista))
      return lista.map((el) => {
        let novoObj = {};
        novoObj.value = el.calibracaoId;
        novoObj.text = el[texto];
        return novoObj;
      });
    return {
      value: lista.calibracaoId,
      text: lista[texto],
    };
  }
}
