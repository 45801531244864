<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-certificado
      v-model="certificados"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.certificados')"
      obrigatorio
      prevent-acoes
      multiplos
      :disabled="form.status == 'Concluido'"
      @remover-opcao="removerOpcao"
    />
    <input-select
      v-model="form.subCategoriaCertificado"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaCertificado"
      :loading="loading.subCategoriaCertificado"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputCertificado } from '@/components/inputs';
import { DropdownCertificado } from '@common/models/geral/DropdownCertificado';
export default {
  components: {
    InputCertificado,
  },
  props: {
    form: {},
  },
  data() {
    return {
      certificados: null,
      loading: {
        subCategoriaCertificado: true,
      },
      opcoes: {
        subCategoriaCertificado: [],
      },
    };
  },
  watch: {
    certificados(value) {
      if (!this.form.id) {
        this.form.certificados = value;
      } else {
        value.forEach((certificado) => {
          let certificadoInserido = this.form.certificados.find((el) => el.calibracaoId == certificado.value);
          if (!certificadoInserido) {
            this.form.certificados.push({
              id: null,
              calibracaoId: certificado.value,
            });
          }
        });
      }
    },
  },
  mounted() {
    this.buscarSubCategoria();
    if (this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.form.certificados = []
      this.certificados = new DropdownCertificado(this.form.certificadosEditar.map((item) => item));

      this.form.certificadosEditar.forEach((certificado) => {
        this.form.certificados.push({
          id: certificado.id,
          calibracaoId: certificado.calibracaoId,
        });
      });
    },
    buscarSubCategoria: function () {
      this.loading.subCategoriaCertificado = true;
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteCertificado'
      )
        .then((res) => {
          this.opcoes.subCategoriaCertificado = res;
        })
        .finally(() => {
          this.loading.subCategoriaCertificado = false;
        });
    },
    removerOpcao: function(indice) {
      this.form.certificados.splice(indice, 1);
    }
  },
};
</script>
