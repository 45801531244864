<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-ordem-servico
      v-model="ordensServico"
      obrigatorio
      class="col-12 col-md-6 text-center"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.ordens_servicos')"
      :multiplos="false"
      :disabled="form.status == 'Concluido'"
    />
    <input-select
      v-model="form.subCategoriaOrdemServico"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaOrdensServicos"
      :loading="loading.subCategoriaOrdensServicos"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputOrdemServico } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
components: {
    InputOrdemServico,
  },
  props: {
    form: {}
  },
  data() {
    return {
      ordensServico: null,
      loading: {
        subCategoriaOrdensServicos: true,
      },
      opcoes: {
        subCategoriaOrdensServicos: [],
      },
    };
  },
   watch: {
     ordensServico(value) {
      if(!this.form.id){
        this.form.ordensServico = value
      } else {
        let ordensServicoInserido = this.form.ordensServico.find((el) => el.ordemServicoId == value.value);
        if(!ordensServicoInserido) {
          this.form.ordensServico.push({
            id: null,
            ordemServicoId: value.value,
          });
        }
      }
    }
  },
  mounted() {
    this.buscarSubCategoria();
    if(this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function() {
      this.form.ordensServico = []
      this.ordensServico = new DropdownModel(this.form.ordensServicoEditar?.map(item => item.ordemServico), 'codigo')[0]

      this.form.ordensServicoEditar.forEach((ordensServico) => {
            this.form.ordensServico.push({
              id: ordensServico.id,
              ordemServicoId: ordensServico.ordemServico.id,
            });
      });
    },
    buscarSubCategoria: function(){
      this.loading.subCategoriaOrdensServicos = true;
      EnumeradorService.buscar('EnumSubCategoriaDuvidaSolicitacaoParticipanteOrdemServico')
      .then((res) => {
        this.opcoes.subCategoriaOrdensServicos = res
      })
      .finally(() => {
        this.loading.subCategoriaOrdensServicos = false;
      });
    },
  }
};
</script>
