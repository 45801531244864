<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-servico-campo
      v-model="servicosCampo"
      class="col-12 col-md-6"
      :label="
        $t('modulos.duvida_solicitacao_participante.formulario.servicos_campos')
      "
      obrigatorio
      :multiplos="false"
      :disabled="form.status == 'Concluido'"
    />
    <input-select
      v-model="form.subCategoriaServicoCampo"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaServicoCampo"
      :loading="loading.subCategoriaServicoCampo"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputServicoCampo } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  components: {
    InputServicoCampo,
  },
  props: {
    form: {},
  },
  data() {
    return {
      servicosCampo: null,
      loading: {
        subCategoriaServicoCampo: true,
      },
      opcoes: {
        subCategoriaServicoCampo: [],
      },
    };
  },
  watch: {
    servicosCampo(value) {
      if (!this.form.id) {this.form.servicosCampo = value;
      } else {
        let servicosCampoInserido = this.form.servicosCampo.find((el) => el.servicoId == value.value);
        if (!servicosCampoInserido) {
          this.form.servicosCampo.push({
            id: null,
            servicoId: value.value,
          });
        }
      }
    },
  },
  mounted() {
    this.buscarSubCategoria();
    if (this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function () {
      this.form.servicosCampo = [];
      this.servicosCampo = new DropdownModel(this.form.servicosCampoEditar.map((item) => item.servico))[0];

      this.form.servicosCampoEditar.forEach((servicosCampo) => {
        this.form.servicosCampo.push({
          id: servicosCampo.id,
          servicoId: servicosCampo.servico.id,
        });
      });
    },
    buscarSubCategoria: function () {
      this.loading.subCategoriaServicoCampo = true;
      EnumeradorService.buscar(
        'EnumSubCategoriaDuvidaSolicitacaoParticipanteServicoCampo'
      )
        .then((res) => {
          this.opcoes.subCategoriaServicoCampo = res;
        })
        .finally(() => {
          this.loading.subCategoriaServicoCampo = false;
        });
    },
  },
};
</script>
