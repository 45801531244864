<template>
  <modal-padrao
    ref="modal"
    max-width="900px"
    :titulo="$t('modulos.calibracao.anexos.titulo_modal')"
    :titulo-ok="$t('geral.botoes.salvar')"
    @ok="salvar"
  >
    <div class="row">
      <input-file-drag-n-drop
        v-model="form.arquivo"
        class="col-12"
        :multiplos="false"
      />
    </div>
  </modal-padrao>
</template>
<script>
import _ from 'lodash';
import helpers from '@common/utils/helpers';
export default {
  data: function () {
    return {
      form: {
        id: null,
        nomeArquivo: '',
        arquivo: [],
      },
    };
  },
  methods: {
    abrirModal: function () {
      this.form = {
        id: '',
        nomeArquivo: '',
        arquivo: [],
      };
      this.$refs.modal.abrirModal();
    },
    salvar: function () {
      let form = _.cloneDeep(this.form);

      form.id = helpers.gerarNumeroAleatorio()
      form.arquivo = this.form.arquivo[0].base64
      form.nomeArquivo = this.form.arquivo[0].nome

      this.$emit('atualizar-lista-anexos', form)
      this.$refs['modal'].fecharModal();
    },
  },
};
</script>
