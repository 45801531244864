import _ from 'lodash';
import store from '@/store';
export class DuvidaSolicitacaoParticipanteModel {
  constructor({
    id,
    dataAbertura = new Date(),
    comentario,
    participanteId = store.getters['Autenticacao/participanteSelecionado'],
    categoria,
    subCategoriaInstrumento,
    subCategoriaCertificado,
    subCategoriaOrdemServico,
    subCategoriaServicoCampo,
    subCategoriaOrcamento,
    subCategoriaContrato,
    instrumentos,
    certificados,
    ordensServico,
    servicosCampo,
    orcamentos ,
    contratos,
    anexos = [],
    status,
    dataConclusao,
    subCategoria,
    usuario,
    }) {
    this.id = id;
    this.dataAbertura = dataAbertura;
    this.comentario = comentario;
    this.participanteId = participanteId;
    this.categoria = categoria;
    this.subCategoriaInstrumento =subCategoriaInstrumento;
    this.subCategoriaCertificado =subCategoriaCertificado;
    this.subCategoriaOrdemServico =subCategoriaOrdemServico;
    this.subCategoriaServicoCampo =subCategoriaServicoCampo;
    this.subCategoriaOrcamento =subCategoriaOrcamento;
    this.subCategoriaContrato =subCategoriaContrato;
    this.instrumentos = [];
    if(instrumentos) this.instrumentosEditar = instrumentos
    this.certificados = certificados;
    if(certificados) this.certificadosEditar = certificados
    this.ordensServico = ordensServico;
    if(ordensServico) this.ordensServicoEditar = ordensServico
    this.servicosCampo = servicosCampo;
    if(servicosCampo) this.servicosCampoEditar = servicosCampo
    this.orcamentos = orcamentos;
    if(orcamentos) this.orcamentosEditar = orcamentos
    this.contratos = contratos;
    if(contratos) this.contratosEditar = contratos
    this.anexos = anexos;
    if(anexos) this.anexos = anexos.map(item => ({...item, flagSistema: true}))
    this.status = status;
    this.dataConclusao = dataConclusao;
    this.subCategoria = subCategoria;
    this.usuario = usuario;
  }
    get request() {

    let retorno = _.cloneDeep(this);
    if(!this.id)  {
      retorno.instrumentos = this.instrumentos?.map(item => item.value)
      retorno.certificados = this.certificados?.map(item => item.value)
      retorno.ordensServico = this.ordensServico.value ? [this.ordensServico?.value] : null
      retorno.servicosCampo = this.servicosCampo.value ? [this.servicosCampo?.value] : null
      retorno.orcamentos = this.orcamentos?.map(item => item.value)
      retorno.contratos = this.contratos?.map(item => item.value)
    }
    retorno.anexos = this.anexos?.map(item => {
      return item.flagSistema ? item : {...item, id: null };
    });
    return retorno;
    }
}
