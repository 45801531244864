<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-instrumento
      v-model="instrumentos"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.instrumentos')"
      obrigatorio
      prevent-acoes
      multiplos
      :disabled="form.status == 'Concluido'"
      @remover-opcao="removerOpcao"
    />
    <input-select
      v-model="form.subCategoriaInstrumento"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaInstrumento"
      :loading="loading.subCategoriaInstrumento"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputInstrumento } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
components: {
    InputInstrumento,
  },
  props: {
    form: {}
  },
  data() {
    return {
      instrumentos: null,
      loading: {
        subCategoriaInstrumento: true,
      },
      opcoes: {
        subCategoriaInstrumento: [],
      },
    };
  },
  watch: {
    instrumentos(value) {
      if(!this.form.id) {
        this.form.instrumentos = value
      } else {
        value.forEach((instrumento) => {
          let instrumentoInserido = this.form.instrumentos.find((el) => el.instrumentoId == instrumento.value);
          if (!instrumentoInserido) {
              this.form.instrumentos.push({
                id: null,
                instrumentoId: instrumento.value,
              });
          }
        });
      }
    }
  },
  mounted() {
    this.buscarSubCategoria();
    if(this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function() {
      this.form.instrumentos = []
      this.instrumentos = new DropdownModel(this.form.instrumentosEditar.map(item => item.instrumento), 'codigo')

      this.form.instrumentosEditar.forEach((instrumento) => {
            this.form.instrumentos.push({
              id: instrumento.id,
              instrumentoId: instrumento.instrumento.id,
            });
      });
    },
    buscarSubCategoria: function(){
      this.loading.subCategoriaInstrumento = true;
      EnumeradorService.buscar('EnumSubCategoriaDuvidaSolicitacaoParticipanteInstrumento')
      .then((res) => {
        this.opcoes.subCategoriaInstrumento = res
      })
      .finally(() => {
        this.loading.subCategoriaInstrumento = false;
      });
    },
    removerOpcao: function(indice) {
      this.form.instrumentos.splice(indice, 1);
    }
  }
};
</script>
