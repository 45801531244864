<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          @click="excluirRegistros"
        >
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <botao-padrao
        class="mt-3"
        @click="anexarArquivo"
      >
        {{ $t('modulos.calibracao.anexos.botoes.anexar_arquivo') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-if="form.anexos?.length"
      ref="tabela"
      v-model="tabela.selecionados"
      :dados="form.anexos"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      @paginar="atualizarListaAnexos"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <botao-padrao
              text
              color="secondary"
              @click="download(item.item)"
            >
              {{ $t('geral.botoes.download') }}
            </botao-padrao>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
    <modal-anexos
      ref="modalAnexo"
      @atualizar-lista-anexos="atualizarListaAnexos"
    />
  </div>
</template>
<script>
import ModalAnexos from './modais/ModalAnexos.vue';
import helpers from '@common/utils/helpers';
export default {
  components: {
    ModalAnexos,
  },
  props: {
    form: {},
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nomeArquivo',
            text: this.$t('modulos.calibracao.anexos.formulario.nome_arquivo'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados?.length > 0;
    },
  },
  methods: {
    excluirRegistros: function () {
      this.tabela.selecionados.forEach((item) => {
           this.form.anexos.splice(this.form.anexos.indexOf(item),1);
      })
    },
    anexarArquivo: function () {
      this.$refs['modalAnexo'].abrirModal();
    },
    download: function (item) {
      helpers.baixarBase64(item.arquivo, item.nomeArquivo);
    },
    atualizarListaAnexos: function (anexo) {
      this.form.anexos.push(anexo)
    }
  },
};
</script>
