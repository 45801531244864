<template>
  <div>
    <v-form
      ref="form"
      class="row mt-4"
    >
      <input-select
        v-model="form.categoria"
        class="col-12 col-md-4"
        obrigatorio
        :label="$t('modulos.duvida_solicitacao_participante.formulario.tipo_duvida_solicitacao')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoes.categoria"
        :loading="loading.categoria"
        :disabled="!!form.id"
      />
      <instrumento
        v-if="form.categoria === 'Instrumentos'"
        :form="form"
        class="col-12 col-md-8"
      />
      <certificado
        v-if="form.categoria === 'Certificados'"
        :form="form"
        class="col-12 col-md-8"
      />
      <ordem-servico
        v-if="form.categoria === 'OrdensServicos'"
        :form="form"
        class="col-12 col-md-8"
      />
      <servico-campo
        v-if="form.categoria === 'ServicosInstalacoesCliente'"
        :form="form"
        class="col-12 col-md-8"
      />
      <orcamento
        v-if="form.categoria === 'Orcamento'"
        :form="form"
        class="col-12 col-md-8"
      />
      <contrato
        v-if="form.categoria === 'Contrato'"
        :form="form"
        class="col-12 col-md-8"
      />
      <input-textarea
        v-if="form.categoria"
        v-model="form.comentario"
        class="col-12 col-md-12"
        :label="$t('modulos.duvida_solicitacao_participante.formulario.duvida_solicitacao')"
        obrigatorio
        :max="200"
        trim
        :disabled="form.status == 'Concluido'"
      />
    </v-form>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import Instrumento from './DuvidaSolicitacaoInstrumento';
import Certificado from './DuvidaSolicitacaoCertificado';
import OrdemServico from './DuvidaSolicitacaoOrdemServico';
import ServicoCampo  from './DuvidaSolicitacaoServicoCampo';
import Orcamento  from './DuvidaSolicitacaoOrcamento';
import Contrato  from './DuvidaSolicitacaoContrato';
export default {
  components: {
    Instrumento,
    Certificado,
    OrdemServico,
    ServicoCampo,
    Orcamento,
    Contrato,
  },
  props: {
    form: {},
    id: String,
  },
  data() {
    return {
      valido: false,
      model: null,
      loading: {
        categoria: true,
      },
      opcoes: {
        categoria: [],
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.duvida_solicitacao_participante.titulos.editar');
      return this.$t('modulos.duvida_solicitacao_participante.titulos.novo');
    },
  },
  watch: {
    'form.categoria': {
      handler() {
        if(!this.form.id) {
          this.form.comentario = null
          this.form.instrumentos = []
          this.form.subCategoriaInstrumento = null
          this.form.certificados = []
          this.form.subCategoriaCertificado = null
          this.form.ordensServico = []
          this.form.subCategoriaOrdemServico = null
          this.form.servicosCampo = []
          this.form.subCategoriaServicoCampo = null
          this.form.orcamentos = []
          this.form.subCategoriaOrcamento = null
          this.form.contratos = []
          this.form.subCategoriaContrato = null
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.buscarCategoriaParticipante()
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscarCategoriaParticipante: function(){
      this.loading.categoria = true;
      EnumeradorService.buscar('EnumCatetegoriaDuvidaSolicitacaoParticipante')
      .then((res) => {
        this.opcoes.categoria = res
      })
      .finally(() => {
        this.loading.categoria = false;
      });
    },
  },
};
</script>
