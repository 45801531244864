<template>
  <span class="row my-0 mx-0 py-0 px-0">
    <input-contrato
      v-model="contratos"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.contratos')"
      obrigatorio
      multiplos
      :disabled="form.status == 'Concluido'"
    />
    <input-select
      v-model="form.subCategoriaContrato"
      class="col-12 col-md-6"
      :label="$t('modulos.duvida_solicitacao_participante.formulario.assunto')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.subCategoriaContrato"
      :loading="loading.subCategoriaContrato"
      :disabled="form.status == 'Concluido'"
    />
  </span>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputContrato } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';

export default {
components: {
    InputContrato,
  },
  props: {
    form: {}
  },
  data() {
    return {
      contratos: null,
      loading: {
        subCategoriaContrato: true,
      },
      opcoes: {
        subCategoriaContrato: [],
      },
    };
  },
  watch: {
    contratos(value) {
      if(!this.form.id) { this.form.contratos = value
      } else {
        value.forEach((contrato) => {
          let contratoInserido = this.form.contratos.find((el) => el.contratoId == contrato.value);
          if (!contratoInserido) {
              this.form.contratos.push({
                id: null,
                contratoId: contrato.value,
              });
          }
        });
      }

    }
  },
  mounted() {
    this.buscarSubCategoria();
    if(this.form.id) this.iniciarForm();
  },
  methods: {
    iniciarForm: function() {
      this.form.contratos = []
      this.contratos = new DropdownModel(this.form.contratosEditar.map(item => item.contrato), 'codigo')

      this.form.contratosEditar.forEach((contrato) => {
            this.form.contratos.push({
              id: contrato.id,
              contratoId: contrato.contrato.id,
            });
      });
    },
    buscarSubCategoria: function(){
      this.loading.subCategoriaContrato = true;
      EnumeradorService.buscar('EnumSubCategoriaDuvidaSolicitacaoParticipanteContrato')
      .then((res) => {
        this.opcoes.subCategoriaContrato = res
      })
      .finally(() => {
        this.loading.subCategoriaContrato = false;
      });
    },
  }
};
</script>
