<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.duvida_solicitacao_participante.formulario.abas.dados') }}
      </v-tab>
      <v-tab :disabled="!id">
        {{ $t('modulos.duvida_solicitacao_participante.formulario.abas.comentario') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.duvida_solicitacao_participante.formulario.abas.anexo') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-duvida-solicitacao
          ref="dados"
          :form="form"
        />
      </v-tab-item>
      <v-tab-item>
        <comentario
          :form="form"
          origen="duvidaSolicitacaoParticipante"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos :form="form" />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        :disabled="form.status == 'Concluido'"
        @click="confirmacaoSalvar"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import DuvidaSolicitacaoParticipanteService from '@common/services/cadastros/DuvidaSolicitacaoParticipanteService';
import { DuvidaSolicitacaoParticipanteModel } from '@common/models/cadastros/DuvidaSolicitacaoParticipanteModel';
import DadosDuvidaSolicitacao from './components/DadosDuvidaSolicitacao';
import Comentario from '../../../../components/comentario/Comentario.vue';
import Anexos from './components/Anexos';
import helpers from '@common/utils/helpers';
export default {
    components: {
    DadosDuvidaSolicitacao,
    Comentario,
    Anexos,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      indiceAba: 0,
      form: new DuvidaSolicitacaoParticipanteModel({}),
      opcoes: {
        subCategoriaInstrumento: [],
      }
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.duvida_solicitacao_participante.titulos.editar');
      return this.$t('modulos.duvida_solicitacao_participante.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'DuvidaSolicitacaoParticipante', 'Deletar');
    }
    helpers.redirecionarSemPermissao(this, 'DuvidaSolicitacaoParticipante', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      DuvidaSolicitacaoParticipanteService.buscar(this.id)
        .then((res) => {
          this.form = new DuvidaSolicitacaoParticipanteModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.duvida_solicitacao_participante.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
    },
    salvar: function () {
      this.valido = this.$refs["dados"].$refs.form.validate();
      if(!this.valido) return
      this.$store.dispatch('Layout/iniciarCarregamento');
      DuvidaSolicitacaoParticipanteService.salvar(this.form.request)
        .then(() => {
          this.$router.push({ name: 'duvida-solicitacao-participante' });
          this.toastSucesso(this.$t(`modulos.duvida_solicitacao_participante.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'duvida-solicitacao-participante' });
      });
    },
  },
};
</script>
